import React from 'react';
import Layout from '../components/layout';
import {
  Section,
  H1,
  Paragraph,
  Icon,
  IconLink,
  Button,
} from '@lsg/components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import {
  ContentfulJobProfile,
  ContentfulPage,
} from '../generated/graphql-types';
import {
  ContentAccessor,
  FootnotesRichtextRenderer,
  GraphQLContentAccessor,
} from '@lws/react-components';
import { buildJSONLD, JSONLDarguments } from '../utils/buildJsonLd';

interface JobPostProps {
  data: {
    contentfulJobProfile: ContentfulJobProfile;
  };
  pageContext: {
    url: string;
  };
}

export interface PageProps {
  page: ContentAccessor<ContentfulPage>;
}

export default class JobPost extends React.Component<JobPostProps> {
  render() {
    const accessorData = new GraphQLContentAccessor(
      this.props.data.contentfulJobProfile
    );
    const jobTitle = accessorData.text('jobTitle');
    const jobDescription = accessorData.richText('jobDescription');
    const createdAt = accessorData.field('createdAt').raw();
    const updatedAt = accessorData.field('updatedAt').raw();
    const jobLocation = accessorData.field('jobLocation').raw();
    return (
      <Layout>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              buildJSONLD({
                jobTitle,
                jobDescription,
                createdAt,
                updatedAt,
                jobLocation,
              } as JSONLDarguments)
            )}
          </script>
        </Helmet>
        <Section>
          <IconLink
            to={'/jobs/'}
            iconName={Icon.Names.interaction_arrows_arrowLeft}
          >
            Züruck
          </IconLink>
          <H1>{jobTitle}</H1>
          <FootnotesRichtextRenderer
            content={jobDescription}
            wrapper={Paragraph}
          />
          <Button to="/job-application/">Jetzt bewerben</Button>
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query($url: String!) {
    contentfulJobProfile(jobUrl: { eq: $url }) {
      jobTitle
      jobDescription {
        json
      }
      createdAt
      updatedAt
      jobLocation {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
    }
  }
`;
